// getPrivateShowStatus.js
'use strict';
import createCachedSelector from '../resource/createCachedSelector.js';

import getLiveStreamData from '../selector/getLiveStreamData.js';
import { privateShowStatus as privateShowConstant } from '../resource/liveStreamConstants.js';

/**
 * Select private show status by stream id
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} streamId - stream id.
 * @return {string} The selected private show status.
 */
const getPrivateShowStatus = createCachedSelector(
  (state, streamId) =>
    getLiveStreamData(state, [streamId, 'privateShow', 'status'], 'isFunding'),
  (state, streamId) =>
    getLiveStreamData(
      state,
      [streamId, 'privateShow', 'status'],
      'isFundingFinalCall'
    ),
  (state, streamId) =>
    getLiveStreamData(
      state,
      [streamId, 'privateShow', 'status'],
      'isConsidering'
    ),
  (state, streamId) =>
    getLiveStreamData(
      state,
      [streamId, 'privateShow', 'status'],
      'isRefunding'
    ),
  (state, streamId) =>
    getLiveStreamData(
      state,
      [streamId, 'privateShow', 'status'],
      'isPreparing'
    ),
  (state, streamId) =>
    getLiveStreamData(
      state,
      [streamId, 'privateShow', 'status'],
      'isPerforming'
    ),
  (
    isFunding,
    isFundingFinalCall,
    isConsidering,
    isRefunding,
    isPreparing,
    isPerforming
  ) => {
    if (isPerforming) {
      return privateShowConstant.PERFORMING;
    }
    if (isPreparing) {
      return privateShowConstant.PREPARING;
    }
    if (isRefunding) {
      return privateShowConstant.REFUNDING;
    }
    if (isConsidering) {
      return privateShowConstant.CONSIDERING;
    }
    if (isFundingFinalCall) {
      return privateShowConstant.FUNDING_FINAL_CALL;
    }
    if (isFunding) {
      return privateShowConstant.FUNDING;
    }
    return undefined;
  }
)((state, streamId) => `${streamId}`);

export default getPrivateShowStatus;
