// getGoalData.js
'use strict';
import { Collection } from 'immutable';
import createCachedSelector from '../resource/createCachedSelector.js';

/**
 * Select goal data by
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {array} selectPath - select path.
 * @param {string} dataKey - select data key.
 * @return {any} The selected goal data.
 */
const getGoalData = createCachedSelector(
  (state, selectPath = []) => state.getIn(['goals', ...selectPath]),
  (state, selectPath, dataKey) => dataKey,
  (goal, dataKey = '') => {
    let result = goal;
    if (dataKey) {
      result = goal?.get(dataKey);
      if (dataKey === 'currentGoalLevel') {
        const progress = goal?.get('progress') || 0;
        const levels = goal?.get('levels').toJS() || [];
        if (levels?.length > 0)
          result = levels.find(level => progress < level?.target) || undefined;
      }
    }

    return result instanceof Collection ? result.toJS() : result;
  }
)((state, selectPath, dataKey) => `${selectPath.join(':')}:${dataKey}`);

export default getGoalData;
