// OpacityBreath.js
'use strict';
import { css, keyframes } from 'styled-components';

const shiningKeyframes = keyframes`
  to {
    opacity: 0.6;
  }
`;

export const OpacityBreath = ({ duration = '0.5' } = {}) => css`
  animation: ${duration}s linear infinite alternate ${shiningKeyframes};
`;

export default OpacityBreath;
