// formatTimeString.js
import { TranslationNamespace } from '../resource/translationNamespace.js';

const ONE_MINUTE_TO_SECONDS = 60;
const ONE_HOUR_TO_SECONDS = 60 * 60;

/**
 * Format the timestamp to the h:mm:ss string
 * @param {number} timestamp - in milliseconds
 * @return {string}
 */
export const formatTimeString = timestamp => {
  const s = Math.floor(timestamp / 1000);
  const sec = s % 60;
  const m = Math.floor(s / 60);
  const min = m % 60;
  const h = Math.floor(m / 60);

  const secStr = sec.toString().padStart(2, '0');
  const minStr = min.toString().padStart(2, '0');
  const hrStr = h.toString().padStart(2, '0');

  if (h > 0) return `${hrStr}:${minStr}:${secStr}`;
  return `${minStr}:${secStr}`;
};

/**
 * Format the seconds to the mm:ss string
 * @param {number} seconds - the second in unix time
 * @return {string} // hh:mm:ss
 */
export const formatTimeStringBySec = (seconds = 0) => {
  const hours = Math.floor(seconds / ONE_HOUR_TO_SECONDS);
  const mins = Math.floor(
    (seconds % ONE_HOUR_TO_SECONDS) / ONE_MINUTE_TO_SECONDS
  );
  const secs = Math.floor(
    (seconds % ONE_HOUR_TO_SECONDS) % ONE_MINUTE_TO_SECONDS
  );

  const formattedHours = `${hours}`.padStart(2, '0');
  const formattedMinutes = `${mins}`.padStart(2, '0');
  const formattedSeconds = `${secs}`.padStart(2, '0');

  if (seconds >= ONE_HOUR_TO_SECONDS) {
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }
  if (seconds >= ONE_MINUTE_TO_SECONDS) {
    return `${formattedMinutes}:${formattedSeconds}`;
  }
  return `00:${formattedSeconds}`;
};

/**
 * Formant the time duration
 * @param {number} unixTime - the second in unix time
 * @param {Function} t - translate function
 * @return {string}
 */
export const formatTimeWithI18n = (unixTime, t) => {
  const s = unixTime;
  const m = Math.floor(s / 60);
  const h = Math.floor(m / 60);

  if (h > 0) {
    return t('hour_unit', {
      ...[h],
      ns: TranslationNamespace.POST,
    });
  } else if (m > 0) {
    return t('minute_unit', {
      ...[m],
      ns: TranslationNamespace.POST,
    });
  } else {
    return t('second_unit', {
      ...[s],
      ns: TranslationNamespace.POST,
    });
  }
};
